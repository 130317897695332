<script setup lang="ts">
import type { UseIntersectionObserverOptions } from '@vueuse/core';
import { useIntersectionObserver } from '@vueuse/core';
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    tag?: string;
    threshold?: UseIntersectionObserverOptions['threshold'];
    rootMargin?: UseIntersectionObserverOptions['rootMargin'];
  }>(),
  {
    tag: 'div',
    threshold: undefined,
    rootMargin: undefined,
  }
);
const emits = defineEmits<{
  enter: [entry: IntersectionObserverEntry];
  leave: [entry: IntersectionObserverEntry];
  change: [entry: IntersectionObserverEntry];
}>();
const wrapper = ref<HTMLElement | null>(null);
useIntersectionObserver(
  wrapper,
  ([entry]) => {
    if (entry.isIntersecting) {
      emits('enter', entry);
    } else {
      emits('leave', entry);
    }
    emits('change', entry);
  },
  {
    threshold: props.threshold,
    rootMargin: props.rootMargin,
  }
);
const isMounted = ref(false);
onMounted(() => (isMounted.value = true));
</script>

<template>
  <component :is="tag" ref="wrapper" class="inview">
    <div v-if="isMounted" v-show="false" v-test="'inView'" />
    <slot />
  </component>
</template>
